<template>
  <div id="addPatrolTask" style="z-index: 100">
    <div class="info">
      <div :id="`map${id}`" class="map"></div>
    </div>
  </div>
</template>

<script>
import Marker from "@/assets/biaodian.png";
import Marker2 from "@/assets/biaoji2.png";
import CSSelect from "@/components/common/CSSelect";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import AILabel from "ailabel";
export default {
  name: "robotTaskList",
  components: {
    CSBreadcrumb,
    CSSelect,
  },
  watch: {
    location(newDate, oldData) {
      if (newDate != oldData) {
        if (true) {
          console.log('变化');
          clearTimeout(this.locationTimer);
          this.locationTimer = setTimeout(() => {
            this.removeInit();
            this.addLineSegment(
              this.gMap,
              this.gFirstFeatureLayer,
              this.datas.slice(0, this.location + 1),
              this.drawingStyleOne,
              false
            );                                      
            this.addLineSegment(
              this.gMap,
              this.gFirstFeatureLayer,
              this.datas.slice(this.location, this.datas.length),
              this.drawingStyleeTwo
            );
          }, 100);
        }
      }
    }
  },
  props: ["item"],
  data() {
    return {
      pos:1,
      isSpee: false,
      locationTimer: null,
      location: 0,
      //标记点列表
      isSan: null,
      id: 0,
      datas: [],
      setTimeoutNum: null,
      imageLeft: 0,
      mapUrl: "", // 地图路径
      descs: "", // 地图属性描述
      mapDesc: "", // 地图尺寸
      gMap: null, //AILabel实例
      gFirstFeatureLayer: null, //矢量图层实例(矩形，多边形等矢量)
      gFirstMarker: null,
      gFirstMaskLayer: null,
      drawingStyle: null,
      itemVal: null,
      //矩形样式
      RectStyle: {
        lineWidth: 1.5, //边框宽度
        strokeStyle: "pink", //边框颜色
        fill: true, //是否填充背景色
        fillStyle: "rgb(255,255,255)", //背景颜色
      },
      mapId: "", //地图ID
      taskName: null, //任务名称
      drawingStyleOne: {
        strokeStyle: "#00B694",
        fillStyle: "#00FFFF",
        lineJoin: "round",
        lineCap: "round",
        lineWidth: 1,
        arrow: true,
      },
      drawingStyleeTwo: {
        strokeStyle: "#999",
        fillStyle: "#00FFFF",
        lineJoin: "round",
        lineCap: "round",
        lineWidth: 1,
        arrow: true,
      },
    };
  },
  mounted() {
    console.log('机器人');
    console.log(this.item);
    this.mapUrl = this.item.mapUrl; //地图地址--
    // let descs = this.item.descs; //地图描述属性
    let mapDesc = this.item.mapDesc; //地图尺寸
    // this.descs = {width:11110,height:110};
    this.mapDesc = JSON.parse(mapDesc);
    // this.mapDesc = {
    //     width:258,
    //     height:258 * mapDesc.height/mapDesc.width
    // }
    this.mapId = this.item.id; //地图ID--
    this.datas = JSON.parse(this.item.ponit); //地图坐标点--
    this.id = this.item.id; //--------------------------------------
    this.$nextTick(() => {
      this.initMap();
      this.addLineSegment(
        this.gMap,
        this.gFirstFeatureLayer,
        this.datas.slice(0, 2),
        this.drawingStyleOne
      );
      this.addLineSegment(
        this.gMap,
        this.gFirstFeatureLayer,
        this.datas.slice(1, this.datas.length),
        this.drawingStyleeTwo
      );
      this.isSpee = true;
    }); 
    this.$vc.on('locationRobot'+this.item.deviceId,(res) => {
      console.log('接收到消息'+ res);
      this.location = res;
    })
  },
  created() {},
  beforeDestroy(){
    clearInterval(this.isSan);
  },
  methods: {
    //h获取中心点
    getDistance(data){
      var length = data.length;
      var xArr = data.map(res => {
        return res.x;
      }).reduce((a,b) => a + b)/length;

      var yArr = data.map(res => {
        return res.y
      }).reduce((a,b) => a + b)/length;
      return {x:xArr,y:yArr};
    },
    //计算最大半径
    getDistanceDis(data,zhong){
      var max = 0;
      for(var i =0;i<data.length;i++){
        var ju = Math.pow(zhong.x - data[i].x,2) + Math.pow(zhong.y - data[i].y,2);
        if(ju > max){
          max = ju;
        }
      }
      return Math.sqrt(max);
    },
    //绘制路线
    drawLines(data, gMap, drawingStyle, isAdd = false) {
      //线段
      const pointFeature = new AILabel.Feature.Point(
        `${+new Date()}`, // id
        { ...data, sr: 0 }, // shape
        { name: "第一个矢量图层" }, // props
        { fillStyle: "#0EE46F" }
      );
      this.gFirstFeatureLayer.addFeature(pointFeature);
      //所有标记点的集合
      if (isAdd || isAdd === 0) {
        this.datas.splice(isAdd, 1);
      } else {
        this.datas.push(data);
      }
      //清空之前的标记
      this.removeInit();
      this.addLineSegment(
        gMap,
        this.gFirstFeatureLayer,
        this.datas,
        drawingStyle
      );
    },
    //清空点线
    removeInit() {
      //清空线
      var Features = this.gFirstFeatureLayer.getAllFeatures();
      for (var i = 0; i < Features.length; i++) {
        if (Features[i] && Features[i].id) {
          this.gFirstFeatureLayer.removeFeatureById(Features[i].id);
        }
      }

      //清空标记点
      var marker = this.gMap.markerLayer.getAllMarkers();
      for (var i = 0; i < marker.length; i++) {
        this.gMap.markerLayer.removeMarkerById(marker[i].id);
      }
      //清空序列编号
      var divs = document.getElementsByClassName("s");
      var domLength = divs.length;
      for (var i = 0; i < domLength; i++) {
        divs[0].remove();
      }
    },
    //添加线段
    addLineSegment(
      gMap,
      gFirstFeatureLayer,
      data,
      drawingStyle,
      isBiao = true
    ) {
      const scale = gMap.getScale();
      const width = drawingStyle.lineWidth / scale;
      const polylineFeature = new AILabel.Feature.Polyline(
        `${+new Date()}`, // id
        { points: data, width }, // shape
        { name: "第一个矢量图层" }, // props
        drawingStyle // style
      );
      gFirstFeatureLayer.addFeature(polylineFeature);
      //删除线段
      //gFirstFeatureLayer.removeFeatureById(polylineFeature.id);
      //线段绘制完成添加标注
      //新
      if(!isBiao && this.location != this.datas.length - 1){
        data = data.slice(0,data.length);
      }
      console.log(data.length + 'GF');
      for (var i = 0; i < data.length; i++) {
        this.addMask(gMap, data[i], isBiao);
      }
      //标记点闪
      setTimeout(() => {
        gMap.markerLayer.getAllMarkers().forEach((res) => {
          var div = document.getElementById(res.id);
          if (div) {
            div.style.transition = "all 1s";
            div.style.width = "30px";
          }
        });
      });
      clearInterval(this.isSan);
      this.isSan = setInterval((res) => {
        this.addOrderTag(gMap.markerLayer.getAllMarkers());
      }, 1000);
    },
    //添加标记
    addMask(gMap, data, isBiao) {
      const marker = new AILabel.Marker(
        `${gMap.markerLayer.getAllMarkers().length + 1}maskted${this.id}`, // id
        {
          src: isBiao ? Marker2 : Marker,
          position: data,
          offset: {
            x: -15,
            y: 15,
          },
        }, // markerInfo
        { name: "第一个marker注记" }, // props
        { zIndex: isBiao? 50 : 40 }
      );

      gMap.markerLayer.addMarker(marker);

      
      // setTimeout(() => {
      //   gMap.markerLayer.getAllMarkers().forEach((res) => {
      //     var div = document.getElementById(res.id);
      //     if (div) {
      //       div.style.transition = "all 1s";
      //       div.style.width = "30px";
      //     }
      //   });
      // });
      // clearInterval(this.isSan);
      // this.isSan = setInterval((res) => {
      //   this.addOrderTag(gMap.markerLayer.getAllMarkers());
      // }, 1000);
    },
    addOrderTag(item) {
      if (this.itemVal !== this.location) {
        for (var i = 0; i < item.length; i++) {
          var divd = document.getElementById(item[i]?.id);
          if (divd) {
            divd.style.opacity = 1;
          }
        }
      }
      this.itemVal = this.location;
      var div = document.getElementById(
        item[this.location || this.location == 0 ? this.location : 0]?.id
      );
      if (div) {
        if (div.style.opacity == 0) {
          div.style.opacity = 1;
        } else {
          div.style.opacity = 0;
        }
      }
      if(this.location == this.datas.length){
           this.removeInit();
            this.addLineSegment(
              this.gMap,
              this.gFirstFeatureLayer,
              this.datas,
              this.drawingStyleOne,
              false
            );
      }
    },
    initMap() {
      let drawingStyle = {}; // 绘制过程中样式
      let zhong =this.getDistance(this.datas);
      let zoomL = this.getDistanceDis(this.datas,zhong) * 2 + 50;
      // 实例化
      const gMap = new AILabel.Map("map" + this.id, {
        center: { x: zhong.x, y: zhong.y }, //让图片居中
        zoom: zoomL,
        mode: "PAN",
        refreshDelayWhenZooming: true, // 缩放时是否允许刷新延时，性能更优
        zoomWhenDrawing: true,
        panWhenDrawing: true,
        zoomWheelRatio: 5, // 控制滑轮缩放缩率[0, 10), 值越小，则缩放越快，反之越慢
        withHotKeys: true, // 关闭快捷键
      });
      //this.gMap = gMap;
      // 1. 图片层添加
      const gFirstImageLayer = new AILabel.Layer.Image(
        "layer-image", // id
        {
          src: this.mapUrl,
          width: this.mapDesc.width,
          height: this.mapDesc.height,
          crossOrigin: false, // 如果跨域图片，需要设置为true
        }, // imageInfo
        { name: "第一个图片图层" }, // props
        { zIndex: 5 } // style
      );
      // 添加到gMap对象
      gMap.addLayer(gFirstImageLayer);

      const gFirstFeatureLayer = new AILabel.Layer.Feature(
        "layer-feature", // id
        { name: "第一个矢量图层" }, // props
        { zIndex: 10 } // style
      );

      const gFirstFeaturePoint = new AILabel.Feature.Point(
        "first-feature-point", // id
        { x: this.descs.width, y: this.descs.height }, // shape
        { name: "第一个矢量图层" }, // props
        { fillStyle: "#f00", lineCap: "round" } // style
      );
      gFirstFeatureLayer.addFeature(gFirstFeaturePoint);

      gMap.addLayer(gFirstFeatureLayer);
      // data 代表r半径shape；data1代表sr半径shape
      gMap.events.on("drawDone", (type, data, data1) => {
        if (type === "MARKER") {
          this.addMask(gMap, data);
        } else if (type === "POINT") {
          //绘制点
          this.drawLines(data, gMap, drawingStyle);
        } else if (type === "POLYLINE") {
          this.addLineSegment(gMap, gFirstFeatureLayer, data, drawingStyle);
        }
      });
      //视野范围发生变化
      gMap.events.on("boundsChanged", (data) => {
        return 2222;
      });
      gMap.events.on("featureSelected", (feature) => {
        gMap.setActiveFeature(feature);
      });
      gMap.events.on("featureUnselected", () => {
        // 取消featureSelected
        gMap.setActiveFeature(null);
      });
      gMap.setEditingColor("#1ab394");
      gMap.events.on("featureUpdated", (feature, shape) => {
        feature.updateShape(shape);
      });
      gMap.events.on("featureDeleted", ({ id: featureId }) => {
        gFirstFeatureLayer.removeFeatureById(featureId);
      });
      
      this.gFirstFeatureLayer = gFirstFeatureLayer;
      this.drawingStyle = drawingStyle;
      this.gMap = gMap;
      window.onresize = function () {
        gMap && gMap.resize();
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.info {
  position: relative;
  height: 145px;
  width: 250px;
  padding:0px 0px 20px 14px;
  overflow:hidden;
}

.map {
  overflow: hidden;
  position: relative;
  width: 235px;
  height: 135px;
  // border: 1px dashed #ccc;
}
</style>
